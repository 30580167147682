import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  public headerType!: HeaderType;
  public headerText!: string;
constructor() { }

}

export enum HeaderType{
  none = 'none',
  clientApplicationHeader = 'clientApplicationHeader',
  clientHeader = 'clientHeader',
  clientHeaderLoggedOut = 'clientHeaderLoggedOut',
  dashboardHeader = 'dashboardHeader',
  logoOnly = 'logoOnly'
}
