import { Injectable } from "@angular/core";
import { ApiService } from "../../../../goldstar-share/src/app/api-data/ng-openapi-gen-next/services/api.service";
import { S3FileService } from "../../../../goldstar-share/src/app/services/s3file-service";
import { lastValueFrom } from "rxjs/internal/lastValueFrom";
import { ApplicationFileSaveRequest, PlaidIdentityInfoRequest } from "projects/goldstar-share/src/app/api-data/ng-openapi-gen-next/models";

@Injectable({
	providedIn: 'root',
})
export class ApplicationTestService {
    constructor(private apiV2: ApiService, private fileService: S3FileService) {}

    async runAllTests(plaidDataId: string, userInfoGUID: string, appGUID: string, plaidOutput: any): Promise<boolean> {
        await this.savePlaidDocuments(plaidDataId, userInfoGUID, appGUID);
        let testPromise = [];
        testPromise.push(this.plaidTests(plaidOutput, appGUID));
        testPromise.push(this.onboardAdvisorTests(appGUID));
        testPromise.push(this.ofacTests(appGUID));
        testPromise.push(this.pepTests(appGUID));
        testPromise.push(this.riskScore(appGUID));
        return await Promise.all(testPromise).then(result => {
            return true;
        });
    }

    async savePlaidDocuments(plaidDataId: string, userInfoGUID: string, appGUID: string) {
        try {
            let plaidData: any = await lastValueFrom(this.apiV2.getIdentityVerification( { body: { identityVerificationId: plaidDataId } } ));
            if(plaidData.data.documentary_verification && plaidData.data.documentary_verification.documents) {
                plaidData.data.documentary_verification.documents.forEach(async (doc: any) => {
                    let filePromiseList: Promise<string>[] = [];
                    if(doc.images.cropped_front) filePromiseList.push(this.fileService.uploadFileWithUrl(doc.images.cropped_front, `cropped_front_${doc.attempt}.jpeg`, 'jpeg'));
                    if(doc.images.cropped_back) filePromiseList.push(this.fileService.uploadFileWithUrl(doc.images.cropped_back, `cropped_back_${doc.attempt}.jpeg`, 'jpeg'));
                    if(doc.images.face) filePromiseList.push(this.fileService.uploadFileWithUrl(doc.images.face, `face_${doc.attempt}.jpeg`, 'jpeg'));
                    await Promise.all(filePromiseList).then(results => {
                        results.forEach(async fileGUID => {
                            let fileSaveRequest: ApplicationFileSaveRequest = {
                                attemptNumber: doc.attempt,
                                dob: doc.extracted_data.date_of_birth,
                                documentAuthenticity: doc.analysis.authenticity,
                                documentCategory: doc.extracted_data.category,
                                documentExpired: doc.analysis.extracted_data.expiration_date,
                                documentQuality: doc.analysis.image_quality,
                                expirationDate: doc.extracted_data.expiration_date,
                                extractedDOBMatch: doc.analysis.extracted_data.date_of_birth,
                                extractedIssuerMatch: doc.analysis.extracted_data.issuing_country,
                                extractedNameMatch: doc.analysis.extracted_data.name,
                                issuingCountry: doc.extracted_data.issuing_country,
                                s3FileGUID: fileGUID,
                                testStatus: doc.status,
                                userInfoGUID: userInfoGUID,
                            }
                            await lastValueFrom((this.apiV2.saveApplicationFileReference( { body: fileSaveRequest })));
                        });
                    });
                });
                let testCode: string = 'REVIEW';
                if(plaidData.data.documentary_verification.status === 'success') testCode = 'PASS';
                else if(plaidData.data.documentary_verification.status === 'failed') testCode = 'FAIL';
                let testResponse = await lastValueFrom(this.apiV2.createApplicationTest({ body: {appGUID: appGUID, testName: 'Plaid Document', testCode: testCode} }));
            }
            let filePromiseList: Promise<string>[] = [];
            if(plaidData.data.selfie_check && plaidData.data.selfie_check.selfies) {
                plaidData.data.selfie_check.selfies.forEach(async (selfie: any) => {
                    if(selfie.capture.video_url) filePromiseList.push(this.fileService.uploadFileWithUrl(selfie.capture.video_url, `video_selfie_${selfie.attempt}.webm`, 'webm'));
                    if(selfie.capture.image_url) filePromiseList.push(this.fileService.uploadFileWithUrl(selfie.capture.image_url, `image_selfie_${selfie.attempt}.jpeg`, 'jpeg'));
                    await Promise.all(filePromiseList).then(results => {
                    results.forEach(async fileGUID => {
                        let fileSaveRequest: ApplicationFileSaveRequest = {
                            attemptNumber: selfie.attempt,
                            testStatus: selfie.status,
                            s3FileGUID: fileGUID,
                            userInfoGUID: userInfoGUID
                        }
                        await lastValueFrom(this.apiV2.saveApplicationFileReference({ body: fileSaveRequest }));
                    });
                });
                });
            }
            let testCode: string = 'REVIEW';
            if(plaidData.data.selfie_check.status === 'success') testCode = 'PASS';
            else if(plaidData.data.selfie_check.status === 'failed') testCode = 'FAIL';
            let testResponse = await lastValueFrom(this.apiV2.createApplicationTest({ body: {appGUID: appGUID, testName: 'Plaid Selfie Check', testCode: testCode} }));
        } catch(e) { console.log(e); }
    }

    async plaidTests(plaidOutput: any, appGUID: string): Promise<boolean> {
        try {
            let riskCode: string = 'REVIEW';
            let kycCode: string = 'REVIEW';
            if(plaidOutput && plaidOutput.data) {
                if(plaidOutput.data.kyc_check) {
                    if(plaidOutput.data.kyc_check.status === 'success') kycCode = 'PASS';
                    else if(plaidOutput.data.kyc_check.status === 'failed') kycCode = 'FAIL';
                }
                if(plaidOutput.data.risk_check) {
                    if(plaidOutput.data.risk_check.status === 'success') riskCode = 'PASS';
                    else if(plaidOutput.data.risk_check.status === 'failed') riskCode = 'FAIL';
                }
            }
            let promiseList = [];
            promiseList.push(lastValueFrom(this.apiV2.createApplicationTest({ body: {appGUID: appGUID, testName: 'Plaid', testCode: 'REVIEW'} })));
            promiseList.push(lastValueFrom(this.apiV2.createApplicationTest({ body: {appGUID: appGUID, testName: 'Plaid Risk Check', testCode: riskCode} })));
            promiseList.push(lastValueFrom(this.apiV2.createApplicationTest({ body: {appGUID: appGUID, testName: 'Plaid KYC Check', testCode: kycCode} })));
            await Promise.all(promiseList);
            return true;
        } catch(e) {
            console.log(e);
            return false;
        }
    }

    async onboardAdvisorTests(appGUID: string): Promise<boolean> {
        try {
            const response = await lastValueFrom(this.apiV2.createApplicationTest({ body: { appGUID: appGUID, testName: 'OnBoard Advisor', testCode: 'REVIEW' } }));
            return true;
        } catch(e) { throw e; }
    }

    async ofacTests(appGUID: string): Promise<boolean> {
        try {
            const response = await lastValueFrom(this.apiV2.createApplicationTest({ body: { appGUID: appGUID, testName: 'OFAC', testCode: 'REVIEW' } }));
            return true;
        } catch(e) { throw e; }
    }

    async pepTests(appGUID: string): Promise<boolean> {
        try {
            const response = await lastValueFrom(this.apiV2.createApplicationTest({ body: { appGUID: appGUID, testName: 'PEP', testCode: 'REVIEW' } }));
            return true;
        } catch(e) { throw e; }
    }

    async riskScore(appGUID: string): Promise<boolean> {
        try {
            const response = await lastValueFrom(this.apiV2.createApplicationTest({ body: { appGUID: appGUID, testName: 'Risk Score', testCode: 'REVIEW' } }));
            return true;
        } catch(e) { throw e; }
    }
}