export interface ScreenSizeInfo {
	isChanged: boolean;
	screenSize: ScreenSize;
}

export interface ScreenSizeState {
	screenSizeInfo: ScreenSizeInfo;
}

export enum ScreenSize {
	Small = 'Small',
	Large = 'Large',
	XL = 'XL',
	XXL = 'XXL',
	Mobile = 'Mobile',
}

export enum SpinnerState {
	Show = 'Show',
	Hide = 'Hide',
}
