import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HelpMenuService {

  visibility: boolean = false;
  content: string = '';

  constructor() { }

  showMenu(content: string){
    this.content = content;
    this.visibility = true;
  }
}
