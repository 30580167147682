import { Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(
    public translate: TranslateService,
    private matIconRegistry: MatIconRegistry,
  ){
    translate.addLangs(['en', 'es']);
    translate.setDefaultLang('en');

    this.matIconRegistry.addSvgIcon
  }


}
