import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { ScreenSize } from '../models/model';

@Injectable({
	providedIn: 'root',
})
export class ScreenSizeObserverService {
	private smallScreenObserver$!: Observable<BreakpointState>;
	private largeScreenObserver$!: Observable<BreakpointState>;
	private fullScreenObserver$!: Observable<BreakpointState>;
	private mobileScreenObserver$!: Observable<BreakpointState>;

	private smallScreenSubscriber!: Subscription;
	private largeScreenSubscriber!: Subscription;
	private fullScreenSubscriber!: Subscription;
	private mobileScreenSubscriber!: Subscription;

	constructor(private observer: BreakpointObserver) {
		// small screen observer
		this.smallScreenObserver$ = this.observer.observe(['(max-width: 700px)']);

		// large screen observer
		this.largeScreenObserver$ = this.observer.observe(['(max-width: 800px)']);

		// full screen (desktop and laptop screen observer)
		this.fullScreenObserver$ = this.observer.observe(['(min-width: 900px)']);

		// mobile screen observer
		this.mobileScreenObserver$ = this.observer.observe(['(max-width: 400px)']);
	}

	public subscribeSmallScreenUpdate() {
		this.smallScreenSubscriber = this.smallScreenObserver$.subscribe((event: any) => {
			if (event.matches) {
				let screenSize = ScreenSize.Small;
			}
		});
	}

	public subscribeLargeScreenUpdate() {
		this.largeScreenSubscriber = this.largeScreenObserver$.subscribe((event: any) => {
			if (event.matches) {
				let screenSize = ScreenSize.Large;
			}
		});
	}

	public subscribeFullScreenUpdate() {
		this.fullScreenSubscriber = this.fullScreenObserver$.subscribe((event: any) => {
			if (event.matches) {
				let screenSize = ScreenSize.XL;
			}
		});
	}

	public subscribeMobileScreenUpdate() {
		this.mobileScreenSubscriber = this.mobileScreenObserver$.subscribe((event: any) => {
			if (event.matches) {
				let screenSize = ScreenSize.Mobile;
			}
		});
	}

	ngOnDestroy(): void {
		this.smallScreenSubscriber.unsubscribe();
		this.fullScreenSubscriber.unsubscribe();
		this.largeScreenSubscriber.unsubscribe();
		this.mobileScreenSubscriber.unsubscribe();
	}
}
