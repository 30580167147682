<ngx-spinner *ngIf="showSpinner" bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="line-spin-fade" [fullScreen]="true">
	<p style="color: white">Loading...</p>
</ngx-spinner>
<div class="row mt-2" style="margin-left: 1rem">
	<div class="col-3 mt-2">
		<label>Country Code </label>
		<div>
			<div class="position-rel">
				<dx-select-box searchMode="contains" [items]="countryCodeDropDown" placeholder="Select" [searchEnabled]="true" [(value)]="selectedCountryCode"> </dx-select-box>
				<i class="fa fa-exclamation-circle dx-select-error-symbol" aria-hidden="true"></i>
			</div>
		</div>
	</div>
	<div class="col-3 mt-2">
		<label>Mobile Number</label>
		<div>
			<div class="position-rel">
				<dx-text-box width="100%" [(value)]="phoneNumber"> </dx-text-box>
			</div>
		</div>
	</div>
</div>
<div class="book-appointment-btn-sec">
	<button type="submit" class="book-appointment-btn" (click)="sendMessage()">Send Message</button>
</div>
