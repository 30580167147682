import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FileAcknowledgeData } from '../../../models/models';
import WebViewer from '@pdftron/webviewer';
import { NgxSpinnerService } from 'ngx-spinner';
import { SpinnerState } from '../../../../../../goldstar-internal/src/app/models/models';

@Component({
	selector: 'app-file-acknowledge',
	templateUrl: './file-acknowledge.component.html',
	styleUrls: ['./file-acknowledge.component.scss'],
})
export class FileAcknowledgeComponent implements AfterViewInit, OnInit {
	@ViewChild('fileView') fileView!: ElementRef;
	filePath: string = '';
	fileName: string = '';

	showTermsAndConditionLabel: boolean = false;

	termsAndConditionLabelContent: string = '';

	acknowledgeTermsAndCondition: boolean | null | undefined = false;

	fileExtension: string = '';

	fileAcknowledgeBtnLabel: string = 'Accept';

	enableFormSubmit: boolean = true;

	fileCancelBtnLabel: string = 'Cancel';

	fileLabel!: string;

	showSpinner!: boolean;

	constructor(@Inject(MAT_DIALOG_DATA) public data: FileAcknowledgeData, private spinnerService: NgxSpinnerService) {}

	ngOnInit() {}

	ngAfterViewInit(): void {
		this.filePath = URL.createObjectURL(this.data.file);
		this.fileName = this.data.file.name;
		this.fileLabel = this.data.fileLabel;
		this.fileAcknowledgeBtnLabel = this.data.acknoWledgeDocLabel;
		this.showTermsAndConditionLabel = this.data.showTermsAndConditionLabel;
		this.termsAndConditionLabelContent = this.data.termsAndConditionLabelContent;
		this.fileCancelBtnLabel = this.data.cancelDoc;
		this.enableFormSubmit = this.showTermsAndConditionLabel ? this.acknowledgeTermsAndCondition ?? true : true;
		let fileNameBrokenUp = this.fileName.split('.');
		this.fileExtension = fileNameBrokenUp[fileNameBrokenUp.length - 1];
		console.log(fileNameBrokenUp);
		console.log(this.data.file);
		WebViewer(
			{
				path: '../../../../assets/lib/pdftron',
				initialDoc: this.filePath,
				extension: this.fileExtension,
				disabledElements: [
					'menuButton',
					'leftPanelButton',
					'viewControlsButton',
					'panToolButton',
					'selectToolButton',
					'ribbons',
					'toggleNotesButton',
					'moreButton',
					'toolsHeader',
					'contextMenuPopup',
					'textPopup',
				],
			},
			this.fileView.nativeElement
		).then((instance: any) => {
			instance.UI.setZoomLevel('100%');
		});
	}

	ngOnDestroy(): void {
		console.log('destroyed');
		URL.revokeObjectURL(this.filePath);
	}
	onAcknowledgement(e: any) {
		if (this.showTermsAndConditionLabel) {
			this.enableFormSubmit = this.acknowledgeTermsAndCondition ?? false;
			return;
		}
		this.enableFormSubmit = true;
	}

	onAcknowledgeFile(event: any) {
		this.data.onAcknowledgeDoc(event);
	}

	onCancel(event: any) {
		this.data.onCancel(event);
	}

	toggleSpinner(state: SpinnerState) {
		switch (state) {
			case SpinnerState.Show:
				this.spinnerService.show();
				this.showSpinner = true;
				break;
			case SpinnerState.Hide:
			default:
				this.spinnerService.hide();
				this.showSpinner = false;
				break;
		}
	}
}
