import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToolBarImageSelectorDirective } from './directives/tool-bar-image-selector.directive';

@NgModule({
	declarations: [ToolBarImageSelectorDirective],
	imports: [CommonModule],
	exports: [ToolBarImageSelectorDirective],
})
export class DirectiveModule {}
