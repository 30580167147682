import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseInternalComponent } from './component/base-internal/base-internal.component';
import { DirectiveModule } from '../directive/directive.module';

@NgModule({
	declarations: [BaseInternalComponent],
	imports: [CommonModule, DirectiveModule],
	providers: [],
	exports: [BaseInternalComponent, DirectiveModule],
})
export class BaseAppModule {}
