import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { SpinnerState } from '../../../models/model';
import { LoaderService } from '../../../services/loader.service';

@Component({
	selector: 'app-loader',
	templateUrl: './loader.component.html',
	styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit {
	public spinnerObserver$!: Subscription;
	public showSpinner!: boolean;

	constructor(
		private spinnerService: NgxSpinnerService,
		private loaderSpinner: LoaderService
	) {}

	ngOnInit() {
		this.initializeSubscription();
	}

	initializeSubscription() {
		this.spinnerObserver$ = this.loaderSpinner.updateSpinnerState.subscribe((spinnerState: SpinnerState) => {
			switch (spinnerState) {
				case SpinnerState.Show:
					this.showSpinner = true;
					this.spinnerService.show();
					break;

				default:
				case SpinnerState.Hide:
					this.showSpinner = false;
					this.spinnerService.hide();
					break;
			}
		});
	}
}
