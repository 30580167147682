import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Result } from '../../../../../../goldstar-share/src/app/models/models';
import { BehaviorSubject, Observable, lastValueFrom, of } from 'rxjs';
import { ApiService } from '../../../../../../goldstar-share/src/app/api-data/ng-openapi-gen-next/services';
import { Token } from '../../../../../../goldstar-share/src/app/api-data/ng-openapi-gen-next/models/token';
import { RouteInfo } from '../../../../../../goldstar-share/src/app/api-data/ng-openapi-gen-next/models';
import { ITokenServiceProvider } from '../model';
import { ResultHelper } from '../helper/result-extension';
@Injectable({
	providedIn: 'root',
})

/**
 * It calls an API to generate a token by passing in a UserInfo object.
 * Note: This is not the best place of this service, more suitable will be app module may be?
 */
export class ClientTokenProviderService implements ITokenServiceProvider {
	private readonly defaultTokenValue: Token = {
		value: 'Default',
		expirationTime: '',
	};

	static routes: RouteInfo[] | undefined;

	private readonly defaultRouteValue: RouteInfo[] = [];

	private token$Observable: BehaviorSubject<Token> = new BehaviorSubject(this.defaultTokenValue);
	public allowedRoutesObservable: BehaviorSubject<RouteInfo[]> = new BehaviorSubject(this.defaultRouteValue);

	constructor(
		private http: HttpClient,
		private apiV2: ApiService
	) {}

	/**
	 * fetches the token from server and caches it as part of Token variable
	 * @param Token result
	 * @returns
	 */
	async fetchToken(tokenInfo: any): Promise<Result<Token>> {
		try {
			if (!environment.extIdentityProviderRoot) {
				throw Error('Identity Provider URL could not be fetched');
			}
			const fetchTokenResponse = await lastValueFrom(this.apiV2.acquireToken({ body: tokenInfo }));
			if (fetchTokenResponse.isSuccess && fetchTokenResponse.data?.items && fetchTokenResponse.data.items.length > 0) {
				const token = fetchTokenResponse.data?.items[0];
				this.token$Observable.next(token);
				return ResultHelper.successResponse(token);
			}
			throw new Error(fetchTokenResponse.message ?? 'Otp validation failed');
		} catch (error: any) {
			return ResultHelper.failedResponse(error);
		}
	}

	async fetchAllowedRoutes(): Promise<void> {
		try {
			const allMatchingRoutes = await lastValueFrom(this.apiV2.getExternalAllowedRoutes());
			if (allMatchingRoutes.isSuccess && allMatchingRoutes.data) {
				const allRoutes = allMatchingRoutes.data.items;
				ClientTokenProviderService.routes = allMatchingRoutes.data.items;
				if (allRoutes) {
					this.allowedRoutesObservable.next(allRoutes);
				}
			}
		} catch (error: any) {
			throw new Error('Failed to fetch allowedRouteMaps');
		}
	}

	/**
	 * returns the cached token if valid, if invalid return failed response
	 * @returns: Token result
	 */
	acquireToken(): Observable<Result<Token>> {
		try {
			const token = this.token$Observable.getValue();
			const result = ResultHelper.successResponse(token);
			return of(result);
		} catch (error: any) {
			const failedResult = ResultHelper.failedResponse<Token>(error);
			return of(failedResult);
		}
	}

	/**
	 * returns the cached token if valid, if invalid return failed response
	 * @returns: Token result
	 */
	allowedRoutes(): Observable<Result<RouteInfo[]>> {
		try {
			const token = this.allowedRoutesObservable.getValue();
			const result = ResultHelper.successResponse(token);
			return of(result);
		} catch (error: any) {
			const failedResult = ResultHelper.failedResponse<RouteInfo[]>(error);
			return of(failedResult);
		}
	}
}
