import { ComponentFactoryResolver, Injectable, Injector, StaticProvider, Type } from '@angular/core';
import { BaseComponentDirective } from './base-component.directive';

@Injectable({
	providedIn: 'root',
})
export class GoldenLayoutComponentService {
	private _componentTypeMap = new Map<string, Type<BaseComponentDirective>>();

	constructor(private componentFactoryResolver: ComponentFactoryResolver) {}

	registerComponentType(name: string, componentType: Type<BaseComponentDirective>) {
		this._componentTypeMap.set(name, componentType);
	}

	getRegisteredComponentTypeNames(): string[] {
		const count = this._componentTypeMap.size;
		const result = new Array<string>(count);
		let idx = 0;
		for (let [key, value] of this._componentTypeMap) {
			result[idx++] = key;
		}
		return result;
	}
}
