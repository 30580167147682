import { Injectable } from '@angular/core';
import JSZip from 'jszip';
import {
	EditS3FileNameRequest,
	S3DeleteFileRequest,
	S3DeleteResponse,
	S3File,
	S3FileInfoRequest,
	S3FileUploadRequest,
	S3GetFileDownloadRequest,
	S3PresignedUrlResponse,
	S3UploadDocumentRequest,
	S3VideoResponse,
	S3VideoThumbnailUploadRequest,
} from '../api-data/ng-openapi-gen-next/models';
import { ApiService } from '../api-data/ng-openapi-gen-next/services/api.service';
import { Observable, filter, lastValueFrom, map } from 'rxjs';
import { StrictHttpResponse } from '../api-data/ng-openapi-gen-next/strict-http-response';
import { RequestBuilder } from '../api-data/ng-openapi-gen-next/request-builder';
import { HttpClient, HttpResponse } from '@angular/common/http';
import saveAs from 'file-saver';

export const ErrorRetrievingFile: string = 'Error retrieving file';
export const ErrorUploadingFile: string = 'Error uploading file';

@Injectable({
	providedIn: 'root',
})
export class S3FileService {
	/**
	 * Record used to map file extensions such as `pdf`
	 * to their MIME type such as `application/pdf`
	 *
	 * Included file extensions: `pdf` `doc` `rtf` `docx` `xlsx` `xls` `jpg` `jpeg` `bmp` `tiff` `weba` `webm`
	 *
	 * Usage: `FileType['pdf']` evaluates to `'application/pdf'`
	 */
	FileType: Record<string, string> = {
		pdf: 'application/pdf',
		doc: 'application/msword',
		rtf: 'application/msword',
		docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
		xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
		xls: 'application/vnd.ms-excel',
		jpg: 'image/jpg',
		jpeg: 'image/jpg',
		bmp: 'image/bmp',
		tiff: 'image/tiff',
		mp4: 'video/mp4',
		mov: 'video/mov',
		weba: 'video/webm',
		webm: 'video/webm',
	};

	constructor(private apiV2: ApiService, private http: HttpClient) {}

	getMimeType(fileType: string): string {
		try {
			const mimeType: string = this.FileType[fileType]
			return mimeType;
		} catch(e) {
			throw new Error(`Don't recognize that type`);
		}
	}

	/**
	 * This method compresses and uploads files to current bucket.
	 *
	 * @param name file name (including extension: fileName.pdf/fileName.docx)
	 *
	 * @param file file of type File
	 *
	 * @param fileGUID (Optional) file GUID for which this is a new version
	 *
	 * @returns `Promise<string>` Promise string containing file reference GUID in s3 bucket or error string `File upload error` if upload failed
	 */
	async uploadFile(name: string, file: File, fileGUID?: string): Promise<string> {
		return new Promise((resolve, reject) => {
			try {
				let fileType: string = name.split('.')[-1];
				const zipper: JSZip = new JSZip();
				zipper.file(name, file);
				zipper.generateAsync({ type: 'blob', compression: 'DEFLATE', compressionOptions: { level: 9 } }).then(async (blob) => {
					const reader = new FileReader();
					reader.readAsDataURL(blob);
					reader.onload = async () => {
						const s3UploadRequest: S3FileUploadRequest = {
							fileBody: reader.result as string,
							fileName: name,
							contentType: this.FileType[fileType],
							fileGUID: fileGUID,
						};
						const s3UploadPromise = await lastValueFrom(this.apiV2.uploadS3File({ body: s3UploadRequest }));
						if (s3UploadPromise.isSuccess && s3UploadPromise.data?.s3FileGUID) {
							resolve(s3UploadPromise.data.s3FileGUID);
						} else {
							console.log(ErrorUploadingFile);
							reject(Error(ErrorUploadingFile));
						}
					};
				});
			} catch (e) {
				reject(e);
			}
		});
	}

	/**
	 * Download and decompress file from S3
	 *
	 * @param fileGUID GUID of target file
	 *
	 * @returns `Promise<File>` Returns a File with target file contents
	 */
	async downloadS3File(fileID: string): Promise<File> {
		return new Promise(async (resolve, reject) => {
			try {
				const s3DownloadRequest: S3GetFileDownloadRequest = {
					s3FileGUID: fileID,
				};
				const s3DownloadResponse = await lastValueFrom(this.apiV2.getS3FileDownload({ body: s3DownloadRequest }));
				if (s3DownloadResponse.isSuccess) {
					const fileName: string = s3DownloadResponse.data?.fileName!;
					const fileData: string = s3DownloadResponse.data?.fileData!;
					const fileType: string = s3DownloadResponse.data?.fileType!;
					const byteChars = atob(fileData);
					const byteNums = new Array(byteChars.length);
					for (let i = 0; i < byteChars.length; i++) byteNums[i] = byteChars.charCodeAt(i);
					const byteArray = new Uint8Array(byteNums);
					let zippedBlob: Blob = new Blob([byteArray]);
					const unzipper: JSZip = new JSZip();
					const unzippedFile = await unzipper.loadAsync(zippedBlob);
					Promise.resolve(unzippedFile)
						.then((unzipped) => {
							return unzipped.files[Object.keys(unzipped.files)[0]];
						})
						.then((unzippedFile) =>
							unzipper
								.file(unzippedFile.name)
								?.async('blob')
								.then(async (unzippedBlob) => {
									unzippedBlob = unzippedBlob.slice(0, unzippedBlob.size, this.FileType[fileType]);
									let file: File = new File([unzippedBlob], fileName + '.' + fileType, { type: this.FileType[fileType] });
									resolve(file);
								})
						);
				} else {
					console.log('No file found to download');
					throw 'No file found to download';
				}
			} catch (e) {
				reject(e);
			}
		});
	}

	async downloadS3FileNoCompression(fileID: string): Promise<File> {
		return new Promise(async (resolve, reject) => {
			try {
				const s3DownloadRequest: S3GetFileDownloadRequest = {
					s3FileGUID: fileID,
				};
				const s3DownloadResponse = await lastValueFrom(this.apiV2.getS3FileDownload({ body: s3DownloadRequest }));
				if (s3DownloadResponse.isSuccess) {
					const fileName: string = s3DownloadResponse.data?.fileName!;
					const fileData: string = s3DownloadResponse.data?.fileData!;
					const fileType: string = s3DownloadResponse.data?.fileType!;
					const byteChars = atob(fileData);
					const byteNums = new Array(byteChars.length);
					for (let i = 0; i < byteChars.length; i++) byteNums[i] = byteChars.charCodeAt(i);
					const byteArray = new Uint8Array(byteNums);
					let file: File = new File([byteArray], fileName + '.' + fileType, { type: this.FileType[fileType] });
					resolve(file);

				} else {
					console.log('No file found to download');
					throw 'No file found to download';
				}
			} catch (e) {
				reject(e);
			}
		});
	}

	async downloadStaticS3File(fileID: string): Promise<File> {
		return new Promise(async (resolve, reject) => {
			try {
				const s3DownloadRequest: S3GetFileDownloadRequest = {
					s3FileGUID: fileID,
				};
				const s3DownloadResponse = await lastValueFrom(this.getClientS3FileDownload({ body: s3DownloadRequest }));

				const test = s3DownloadResponse;
				const fileName: string = 'Overdraft Protection.zip';
				const fileData: string = test;
				const fileType: string = 'pdf';
				const byteChars = atob(fileData);
				const byteNums = new Array(byteChars.length);
				for (let i = 0; i < byteChars.length; i++) byteNums[i] = byteChars.charCodeAt(i);
				const byteArray = new Uint8Array(byteNums);
				let zippedBlob: Blob = new Blob([byteArray]);
				const unzipper: JSZip = new JSZip();
				const unzippedFile = await unzipper.loadAsync(zippedBlob);
				Promise.resolve(unzippedFile)
					.then((unzipped) => {
						return unzipped.files[Object.keys(unzipped.files)[0]];
					})
					.then((unzippedFile) =>
						unzipper
							.file(unzippedFile.name)
							?.async('blob')
							.then(async (unzippedBlob) => {
								unzippedBlob = unzippedBlob.slice(0, unzippedBlob.size, this.FileType[fileType]);
								let file: File = new File([unzippedBlob], fileName + '.' + fileType, { type: this.FileType[fileType] });
								resolve(file);
							})
					);
			} catch (e) {
				reject(e);
			}
		});
	}

	/**
	 * Delete target file
	 *
	 * @param fileGUID target file GUID
	 *
	 * @returns `Promise<boolean>` true or false based on success of deletion
	 */
	async deleteFile(fileID: string): Promise<boolean> {
		return new Promise(async (resolve, reject) => {
			try {
				const deleteRequest: S3DeleteFileRequest = {
					s3FileGUID: fileID,
				};
				const deleteDocumentPromise = await lastValueFrom(this.apiV2.deleteS3File({ body: deleteRequest }));
				if (deleteDocumentPromise.isSuccess && deleteDocumentPromise.data) {
					const deleteDocumentResult: S3DeleteResponse = deleteDocumentPromise.data;
					if (deleteDocumentResult) resolve(true);
				}
				resolve(false);
			} catch (e) {
				console.log('Error: ', e);
				reject(e);
			}
		});
	}

	/**
	 * Gets file info for target file: FileGUID, BucketGUID, FileName, FileType
	 *
	 * @param fileId GUID of target file
	 *
	 * @returns `Promise<S3File>` Promise containing S3File object with file info
	 */
	async getFileInfo(fileId: string): Promise<S3File> {
		return new Promise(async (resolve, reject) => {
			try {
				const fileInfoRequest: S3FileInfoRequest = {
					fileGUID: fileId,
				};
				const fileInfoPromise = await lastValueFrom(this.apiV2.getFileInfo({ body: fileInfoRequest }));
				if (fileInfoPromise.isSuccess && fileInfoPromise.data) {
					resolve(fileInfoPromise.data);
				} else throw Error('File not found');
			} catch (e) {
				reject(e);
			}
		});
	}

	/**
	 * Edit file name
	 *
	 * @param fileId GUID of target file
	 * @param newName String containing new file name
	 *
	 * @returns `Promise<boolean>` Promise containing `true` if the update was successful
	 */
	async editFileName(fileId: string, newName: string): Promise<boolean> {
		return new Promise(async (resolve, reject) => {
			try {
				const editS3FileNameRequest: EditS3FileNameRequest = {
					s3FileGUID: fileId,
					label: newName,
				};
				const editFileNamePromise = await lastValueFrom(this.apiV2.editS3FileName({ body: editS3FileNameRequest }));
				if (editFileNamePromise.isSuccess) {
					resolve(true);
				} else resolve(false);
			} catch (e) {
				reject(e);
			}
		});
	}

	/**
	 *
	 * @param url URL of file to be downloaded
	 * @param name Name to save file under
	 * @param fileType Type of file
	 * @returns
	 */
	async uploadFileWithUrl(url: string, name: string, fileType: string): Promise<string> {
		try {
			const request: S3FileUploadRequest = {
				fileURL: url,
				fileName: name,
				contentType: this.FileType[fileType],
			};
			const response = await lastValueFrom(this.apiV2.uploadS3FileFromLink({ body: request }));
			if (response.isSuccess) {
				return response.data?.s3FileGUID!;
			} else throw new Error('Error saving file');
		} catch (e) {
			console.log('Error: ', e);
			throw e;
		}
	}

	private getClientS3FileDownload(params: { body: S3GetFileDownloadRequest }): Observable<any> {
		return this.getClientS3FileDownload$Response(params).pipe(map((r: StrictHttpResponse<any>) => r.body as any));
	}

	getClientS3FileDownload$Response(params: { body: S3GetFileDownloadRequest }): Observable<StrictHttpResponse<void>> {
		console.log('The root url is--,', this.apiV2.rootUrl);
		const rb = new RequestBuilder(this.apiV2.rootUrl, ApiService.GetClientS3FileDownloadPath, 'post');
		if (params) {
			rb.body(params.body, 'application/json');
		}

		return this.http
			.request(
				rb.build({
					responseType: 'text',
					accept: '*/*',
				})
			)
			.pipe(
				filter((r: any) => r instanceof HttpResponse),
				map((r: HttpResponse<any>) => {
					return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
				})
			);
	}

	/**
	 * Gets all files of type video from s3
	 *
	 * @returns `Promise<S3File>` Promise containing S3File object with file info
	 */
	async getAllS3Videos(): Promise<S3VideoResponse[]> {
		return new Promise(async (resolve, reject) => {
			try {
				const filesPromise = await lastValueFrom(this.apiV2.getAllS3Videos());
				if (filesPromise.isSuccess && filesPromise.data) {
					if (filesPromise.data.items) resolve(filesPromise.data.items);
				} else throw Error('File not found');
			} catch (e) {
				reject(e);
			}
		});
	}

	/**
	 * This method uploads video files to current bucket without compression.
	 *
	 * @param file file of type File
	 *
	 * @param fileGUID (Optional) file GUID for which this is a new version
	 *
	 * @returns `Promise<string>` Promise string containing file reference GUID in s3 bucket or error string `File upload error` if upload failed
	 */
	async uploadVideoFileToS3(file: File, fileGUID?: string): Promise<string> {
		return new Promise((resolve, reject) => {
			try {
				let arrFileNameParts = file.name.split('.');
				let fileType: string = arrFileNameParts[arrFileNameParts.length - 1];
				const reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = async () => {
					const s3UploadRequest: S3FileUploadRequest = {
						fileBody: reader.result as string,
						fileName: file.name,
						contentType: this.FileType[fileType],
						fileGUID: fileGUID,
					};
					const s3UploadPromise = await lastValueFrom(this.apiV2.uploadVideoToS3({ body: s3UploadRequest }));
					if (s3UploadPromise.isSuccess && s3UploadPromise.data?.s3FileGUID) {
						resolve(s3UploadPromise.data.s3FileGUID);
					} else {
						console.log(ErrorUploadingFile);
						reject(Error(ErrorUploadingFile));
					}
				};
			} catch (e) {
				reject(e);
			}
		});
	}

	async putVideoUsingPreSignedUrl(url: string, data: File): Promise<boolean> {
		try {
			const content = new Blob([data]).size;
			await lastValueFrom(
				this.http.put(url, data, {
					headers: { 'Content-Length': content.toString() },
				})
			);
			return true;
		} catch (err) {
			console.error('Error: ', err);
			return false;
		}
	}
	async uploadFileUsingPreSignedUrl(file: File, fileGUID?: string): Promise<S3PresignedUrlResponse | null> {
		try {
			const arrFileNameParts = file.name.split('.');
			const fileType: string = arrFileNameParts[arrFileNameParts.length - 1];

			const reader = new FileReader();
			reader.readAsDataURL(file);
			const s3UploadRequest: S3FileUploadRequest = {
				fileBody: '',
				fileName: file.name,
				contentType: this.FileType[fileType],
				fileGUID: fileGUID,
			};

			const s3UploadPromise = await lastValueFrom(this.apiV2.fetchS3UploadPresignedUrl({ body: s3UploadRequest }));

			if (s3UploadPromise.isSuccess && s3UploadPromise.data?.preSignedUrl) {
				const response = await this.putVideoUsingPreSignedUrl(s3UploadPromise.data?.preSignedUrl, file);

				if (response) {
					return s3UploadPromise.data ?? '';
				} else {
					throw new Error('Failed to upload file.');
				}
			} else {
				throw new Error('Could not upload file.');
			}
		} catch (e) {
			console.error('Error: ', e);
			throw new Error('Could not upload file.');
		}
	}

	async uploadVideoThumbnail(file: File, s3VideoFileGUID?: string, s3VideoGUID?: string): Promise<string> {
		return new Promise((resolve, reject) => {
			try {
				let arrFileNameParts = file.name.split('.');
				let fileType: string = arrFileNameParts[arrFileNameParts.length - 1];
				const reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = async () => {
					const s3UploadRequest: S3VideoThumbnailUploadRequest = {
						fileBody: reader.result as string,
						fileName: file.name,
						contentType: this.FileType[fileType],
						videoFileGUID: s3VideoFileGUID,
						s3VideoGUID: s3VideoGUID,
					};
					const s3UploadPromise = await lastValueFrom(this.apiV2.uploadVideoThumbnail({ body: s3UploadRequest }));
					if (s3UploadPromise.isSuccess && s3UploadPromise.data?.s3FileGUID) {
						resolve(s3UploadPromise.data.s3FileGUID);
					} else {
						reject(Error(ErrorUploadingFile));
					}
				};
			} catch (e) {
				reject(e);
			}
		});
	}
}
