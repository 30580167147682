import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { LanguageChangeAction } from '../../../store/store.actions';
import { EditorStyle, LabelMode } from 'devextreme/common';

@Component({
	selector: 'app-client-header-logo-only',
	templateUrl: './client-header-logo-only.component.html',
	styleUrls: ['./client-header-logo-only.component.scss'],
})
export class ClientHeaderLogoOnlyComponent implements OnInit {
	labelMode: LabelMode = 'static';
	stylingMode: EditorStyle = 'outlined';
	languageList: languageData[] = [];
	constructor(
		public translate: TranslateService,
		private store: Store<boolean>
	) {
		translate.addLangs(['en', 'es']);
		translate.setDefaultLang('en');
		const browserLang = translate.getBrowserLang() as string;
		translate.use(browserLang.match(/en|es/) ? browserLang : 'en');
	}

	ngOnInit(): void {
		// this.languageList= this.translate.getLangs();

		for (let item of this.translate.getLangs()) {
			const data: languageData = {
				key: item,
				displayName: item == 'en' ? 'English' : 'Spanish',
			};
			data.key = item;

			this.languageList.push(data);
		}
	}
	onValueChanged(e: any) {
		this.translate.use(e.value.key);
		this.store.dispatch(LanguageChangeAction({ selectedLang: e.value.key }));
	}
}

export interface languageData {
	key: string;
	displayName: string;
}
