import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-loan-application-header',
    templateUrl: 'loan-application-header.component.html',
    styleUrls: ['loan-application-header.component.scss']
})
export class LoanApplicationHeaderComponent {
  @Input() headerText: string = 'hey';

    constructor() { }
}
