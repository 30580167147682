import { Component } from '@angular/core';
import { SpinnerState } from '../../../../../goldstar-internal/src/app/models/models';
import { SendMessageRequest } from '../../../../../goldstar-share/src/app/api-data/ng-openapi-gen-next/models';
import { NgxSpinnerService } from 'ngx-spinner';
import { lastValueFrom } from 'rxjs';
import { ApiService } from '../../../../../goldstar-share/src/app/api-data/ng-openapi-gen-next/services';
import { ErrorHandlingService } from '../../../../../goldstar-share/src/app/services/error-handling.service';

@Component({
	selector: 'app-send-message',
	templateUrl: './send-message.component.html',
	styleUrls: ['./send-message.component.scss'],
})
export class SendMessageComponent {
	showSpinner!: boolean;
	public countryCodeDropDown: string[] = ['US', 'MX'];

	public phoneNumber!: string;

	public selectedCountryCode!: string;

	constructor(
		protected spinnerService: NgxSpinnerService,
		private apiV2: ApiService,
		private errorHandlingService: ErrorHandlingService
	) {}

	async sendMessage() {
		this.toggleSpinner(SpinnerState.Show);
		const otpVerificationRequest: SendMessageRequest = {
			message: 'Hello this is dummy message',
			receiverCountryCode: this.selectedCountryCode == 'US' ? '+1' : '+52',
			receiverPhoneNumber: this.phoneNumber,
		};
		await lastValueFrom(this.apiV2.sendMessage({ body: otpVerificationRequest }))
			.then((response) => {
				if (response.isSuccess) {
					this.toggleSpinner(SpinnerState.Hide);
					this.errorHandlingService.showSuccessMessage('Successfully send message');
					return;
				}
				throw Error('Could send message');
			})
			.catch((error) => {
				this.toggleSpinner(SpinnerState.Hide);
				this.errorHandlingService.showErrorMessage('Error sending message');
			});
	}

	toggleSpinner(state: SpinnerState) {
		switch (state) {
			case SpinnerState.Show:
				this.spinnerService.show();
				this.showSpinner = true;
				break;
			case SpinnerState.Hide:
			default:
				this.spinnerService.hide();
				this.showSpinner = false;
				break;
		}
	}
}
