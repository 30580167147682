import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FluidHeightDirective } from './directives/fluid-height.directive';
import { ControlHeightDirective } from './directives/control-height.directive';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ClientTokenInterceptor } from './interceptor/client-token-interceptor';

@NgModule({
	providers: [DatePipe, { provide: HTTP_INTERCEPTORS, useClass: ClientTokenInterceptor, multi: true }],
	declarations: [FluidHeightDirective, ControlHeightDirective],
	imports: [CommonModule],
	exports: [FluidHeightDirective, ControlHeightDirective],
})
export class ApplicationCommonModule {}
