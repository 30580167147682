import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserTransferStateModule } from '@angular/platform-browser';
import {
	DxTreeListModule,
	DxDataGridModule,
	DxTextBoxModule,
	DxNumberBoxModule,
	DxSchedulerModule,
	DxValidatorModule,
	DxValidationGroupModule,
	DxHtmlEditorModule,
	DxDateBoxModule,
	DxPopupModule,
	DxButtonModule,
	DxSortableModule,
	DxTabPanelModule,
	DxListModule,
	DxTemplateModule,
	DxAutocompleteModule,
	DxScrollViewModule,
	DxDraggableModule,
	DxFileUploaderModule,
	DxDropDownButtonModule,
	DxToolbarModule,
	DxBoxModule,
	DxColorBoxModule,
	DxLookupModule,
	DxResponsiveBoxModule,
	DxSelectBoxModule,
	DxTextAreaModule,
	DxTileViewModule,
	DxValidationSummaryModule,
	DxGanttModule,
	DxSwitchModule,
	DxRadioGroupModule,
	DxDropDownBoxModule,
	DxTreeViewComponent,
	DxTreeViewModule,
	DxCheckBoxModule,
	DxTooltipModule,
	DxMenuModule,
	DxTagBoxModule,
} from 'devextreme-angular';

const Dx_Module = [
	DxTreeListModule,
	DxDataGridModule,
	DxTextBoxModule,
	DxNumberBoxModule,
	DxSchedulerModule,
	DxValidatorModule,
	DxValidationGroupModule,
	DxHtmlEditorModule,
	DxDateBoxModule,
	DxPopupModule,
	DxButtonModule,
	DxSortableModule,
	DxTabPanelModule,
	DxListModule,
	DxTemplateModule,
	DxAutocompleteModule,
	DxScrollViewModule,
	DxDraggableModule,
	DxFileUploaderModule,
	DxDropDownButtonModule,
	DxToolbarModule,
	DxSelectBoxModule,
	DxTextBoxModule,
	DxTemplateModule,
	DxTextAreaModule,
	DxTileViewModule,
	DxResponsiveBoxModule,
	DxBoxModule,
	DxValidationSummaryModule,
	DxColorBoxModule,
	DxLookupModule,
	DxGanttModule,
	DxSwitchModule,
	DxRadioGroupModule,
	DxTemplateModule,
	DxTreeViewModule,
	DxDropDownBoxModule,
	DxCheckBoxModule,
	DxTooltipModule,
	DxTagBoxModule,
	BrowserTransferStateModule,
];

@NgModule({
	declarations: [],
	imports: [CommonModule, ...Dx_Module],
	exports: [
		DxDataGridModule,
		DxTextBoxModule,
		DxNumberBoxModule,
		DxSchedulerModule,
		DxValidatorModule,
		DxValidationGroupModule,
		DxHtmlEditorModule,
		DxDateBoxModule,
		DxPopupModule,
		DxButtonModule,
		DxSortableModule,
		DxTabPanelModule,
		DxListModule,
		DxTemplateModule,
		DxAutocompleteModule,
		DxScrollViewModule,
		DxDraggableModule,
		DxFileUploaderModule,
		DxDropDownButtonModule,
		DxToolbarModule,
		DxSelectBoxModule,
		DxTextBoxModule,
		DxTemplateModule,
		DxTextAreaModule,
		DxTileViewModule,
		DxResponsiveBoxModule,
		DxBoxModule,
		DxValidationSummaryModule,
		DxColorBoxModule,
		DxLookupModule,
		DxGanttModule,
		DxRadioGroupModule,
		DxTemplateModule,
		DxTreeViewModule,
		DxDropDownBoxModule,
		DxCheckBoxModule,
		DxTooltipModule,
		DxTreeListModule,
		DxMenuModule,
		DxTagBoxModule,
		DxSwitchModule,
		BrowserTransferStateModule,
	],
})
export class DxModule {}
