import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, mergeMap } from 'rxjs';
import { SessionService } from '../../../services/session.service';
import { ClientTokenProviderService } from '../services/client-token-provider.service';

@Injectable({
	providedIn: 'root',
})
export class ClientTokenInterceptor implements HttpInterceptor {
	constructor(
		private tokenService: ClientTokenProviderService,
		private sessionService: SessionService
	) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		//return
		//this.tokenService.allowedRoutes().pipe(
		//mergeMap((response) => {
		// Allow call for fetching all route maps
		if (request.url.includes('common/allowedRoutes')) {
			const modifiedRequest = request.clone({
				params: request.params.set('authType', 'noAuth'),
			});
			return next.handle(modifiedRequest);
		}

		const matchingRouteInfo = ClientTokenProviderService.routes?.find((x) => request.url.includes(x.key ?? ''));
		if (matchingRouteInfo) {
			let headers = request.headers;
			if (!!this.sessionService.sessionGUID) {
				headers = request.headers.set('Session', this.sessionService.sessionGUID);
			}
			const modifiedRequest = request.clone({
				params: request.params.set('authType', 'noAuth'),
				headers: headers.set('ModUrl', window.location.href),
			});
			return next.handle(modifiedRequest);
		} else {
			return this.tokenService.acquireToken().pipe(
				mergeMap((response) => {
					if (!!this.sessionService.sessionGUID) {
						const modifiedRequest = request.clone({
							params: request.params.set('authType', 'auth'),
							headers: request.headers.set('Authorization', `${response.data?.value}`).set('Session', this.sessionService.sessionGUID).set('ModUrl', window.location.href),
						});
						return next.handle(modifiedRequest);
					} else {
						const modifiedRequest = request.clone({
							params: request.params.set('authType', 'auth'),
							headers: request.headers.set('Authorization', `${response.data?.value}`),
						});
						return next.handle(modifiedRequest);
					}
				})
			);
		}
		//	})
		//);
	}
}
