import { Injectable } from '@angular/core';
import { AddressInfo } from '../components/shared/address-autocomplete/address-autocomplete.component';

@Injectable({
	providedIn: 'root',
})
export class LocationService implements ILocationService {
	// Earth's mean radius expressed in meters
	private readonly earthRadius: number = 6378137;
	private readonly meterToMileConversionFactor: number = 0.000621371;
	private readonly meterToKilometerConversionFactor: number = 0.001;

	constructor() {}

	/**
	 * Converts a given value to radian
	 * @param value
	 * @returns
	 */
	private toRadian(value: number): number {
		return (value * Math.PI) / 180;
	}

	/**
	 * Given two addresses, returns the distance between them using the 'Haversine' method
	 * @param sourceAddress : Source address info
	 * @param destinationAddress : DestinationAddressInfo
	 * @returns distance in meters
	 */
	public distanceInMeters(sourceAddress: AddressInfo, destinationAddress: AddressInfo): number {
		const sourceLatitude = Number.parseFloat(sourceAddress.latitude ?? '0');
		const sourceLongitude = Number.parseFloat(sourceAddress.longitude ?? '0');

		const destinationLatitude = Number.parseFloat(destinationAddress.latitude ?? '0');
		const destinationLongitude = Number.parseFloat(destinationAddress.longitude ?? '0');

		const latitudeDiff = this.toRadian(destinationLatitude - sourceLatitude);
		var longDiff = this.toRadian(destinationLongitude - sourceLongitude);

		const evaluatedValue =
			Math.sin(latitudeDiff / 2) * Math.sin(latitudeDiff / 2) +
			Math.cos(this.toRadian(sourceLatitude)) * Math.cos(this.toRadian(destinationLatitude)) * Math.sin(longDiff / 2) * Math.sin(longDiff / 2);
		const finalValue = 2 * Math.atan2(Math.sqrt(evaluatedValue), Math.sqrt(1 - evaluatedValue));
		const distance = this.earthRadius * finalValue;
		return distance;
	}

	/**
	 * Given two addresses, returns the distance between them using the 'Haversine' method
	 * @param sourceAddress : Source address info
	 * @param destinationAddress : DestinationAddressInfo
	 * @returns distance in miles
	 */
	public distanceInMile(sourceAddress: AddressInfo, destinationAddress: AddressInfo): number {
		const distanceInMeters = this.distanceInMeters(sourceAddress, destinationAddress);
		return distanceInMeters * this.meterToMileConversionFactor;
	}

	/**
	 * Given two addresses, returns the distance between them using the 'Haversine' method
	 * @param sourceAddress : Source address info
	 * @param destinationAddress : DestinationAddressInfo
	 * @returns distance in KM
	 */
	public distanceInKiloMeter(sourceAddress: AddressInfo, destinationAddress: AddressInfo): number {
		const distanceInMeters = this.distanceInMeters(sourceAddress, destinationAddress);
		return distanceInMeters * this.meterToKilometerConversionFactor;
	}
}

export interface ILocationService {
	distanceInMeters(sourceAddress: AddressInfo, destinationAddress: AddressInfo): number;
	distanceInMile(sourceAddress: AddressInfo, destinationAddress: AddressInfo): number;
	distanceInKiloMeter(sourceAddress: AddressInfo, destinationAddress: AddressInfo): number;
}
