import { Injectable } from '@angular/core';
import { ApiService } from '../../../../goldstar-share/src/app/api-data/ng-openapi-gen-next/services';
import { GetSessionRequest } from '../../../../goldstar-share/src/app/api-data/ng-openapi-gen-next/models';
import { lastValueFrom } from 'rxjs';
import { ErrorHandlingService } from '../../../../goldstar-share/src/app/services/error-handling.service';
import { LocalStorageService } from '../../../../goldstar-share/src/app/services/local.storage.service';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
	providedIn: 'root',
})
export class SessionService {
	sessionGUID: string | null = null;

	constructor(
		private apiV2: ApiService,
		private errorHandler: ErrorHandlingService,
        private storageService: LocalStorageService,
	) {}

    tempExternalOperationKey: string = 'goldstar-external-user-portal-id';

	async generateSession(): Promise<string> {
		try {
            let customerId: string | null = this.storageService.tryGetItem(this.tempExternalOperationKey);
            if(!customerId) {
                const newCustomerId: string = uuidv4();
                this.storageService.setItem(this.tempExternalOperationKey, newCustomerId);
            }
			const sessionRequest: GetSessionRequest = {
				userGUID: customerId ?? '',
				userAgent: navigator.userAgent,
			};
			const response = await lastValueFrom(this.apiV2.getSession({ body: sessionRequest }));
			if (response.isSuccess && response.data) {
				this.sessionGUID = response.data;
				return response.data;
			} else throw Error(`Couldn't generate a Session\n${response}`);
		} catch (e) {
			console.error(e);
			this.errorHandler.showErrorMessage('** Unable to establish a Session **');
			throw e;
		}
	}

	endSession() {
		if (!this.sessionGUID) console.warn('No existing session found');
		else this.sessionGUID = null;
	}
}
