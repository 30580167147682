import { createReducer, on } from '@ngrx/store';
import { LanguageChangeAction, ScreenSizeStateAction } from './store.actions';
import { ScreenSizeInfo } from '../models/model';

export interface StoreState {
	selectedLang: string;
	// screenSizeInfo: ScreenSizeInfo | undefined;
}

export const initialStoreState: StoreState = {
	selectedLang: 'en',
};

export const storeReducer = createReducer(
	initialStoreState,
	on(LanguageChangeAction, (state, payload) => {
		return {
			...state,
			selectedLang: payload.selectedLang,
		};
	}),

	on(ScreenSizeStateAction, (state, payload) => {
		return {
			...state,
			screenSizeInfo: payload.screenSizeInfo,
		};
	})
);
