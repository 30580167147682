import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { SpinnerState } from '../models/model';

@Injectable({
	providedIn: 'root',
})
export class LoaderService {
	updateSpinnerState = new Subject<SpinnerState>();
	constructor() {}

	public showLoader() {
		const spinnerState: SpinnerState = SpinnerState.Show;
		this.updateSpinnerState.next(spinnerState);
	}

	public hideLoader() {
		const spinnerState: SpinnerState = SpinnerState.Hide;
		this.updateSpinnerState.next(spinnerState);
	}

	toggleSpinner(showLoader: boolean) {
		let state = showLoader ? SpinnerState.Show : SpinnerState.Hide;
		switch (state) {
			case SpinnerState.Show:
				this.showLoader();
				break;
			case SpinnerState.Hide:
			default:
				this.hideLoader();
				break;
		}
	}
}
