import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class ApplicationRouteGuard implements CanActivate {
	constructor(private router: Router) {}
	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		const idParam = route.params['id'];
		if (idParam) {
			console.log('ID param is present');
			this.router.navigate([`debitcardppplication/otpValidation/${idParam}`]);
		}
		return false;
	}
}
